<template>
  <overlay
    :show="loading"
  >
    <b-form
      v-if="!loading"
    >
      <app-collapse
        class="content-distribuicao"
      >
        <div class="pl-1 pr-1">
          <b-alert
            :show="getEmpresas.length === 0"
            variant="warning"
            class="m-alert"
          >
            <div class="alert-body d-flex align-items-center">
              <feather-icon
                icon="AlertTriangleIcon"
                size="20"
                class="mr-50 d-none-1000"
              />
              Não há empresas cadastradas para este DR operador.
            </div>
          </b-alert>
        </div>

        <app-collapse-item
          v-for="empresa in getEmpresas"
          :key="empresa.id_empresa"
          class="border-0 mb-2"
          :title="empresa.nome_empresa"
        >
          <b-alert
            :show="empresa.empresa_endereco.length === 0"
            variant="warning"
            class="m-alert"
          >
            <div class="alert-body d-flex align-items-center">
              <feather-icon
                icon="AlertTriangleIcon"
                size="20"
                class="mr-50 d-none-1000"
              />
              Não há endereços cadastrados nesta empresa, atendidos pelo DR operador selecionado.
            </div>
          </b-alert>

          <b-alert
            :show="empresa.possui_enderecos_outros_drs"
            variant="warning"
            class="m-alert"
          >
            <div class="alert-body d-flex align-items-center">
              <feather-icon
                icon="AlertTriangleIcon"
                size="20"
                class="mr-50 d-none-1000"
              />
              Esta empresa possui endereços vinculados a unidades operacionais de estados diferentes, portanto, pode ser atendida por mais de um DR operador.
            </div>
          </b-alert>

          <template slot="header">
            <h4
              class="text-custom-blue"
            >
              {{ empresa.nome_empresa }} |
              <span>
                {{ empresa.cpf_cnpj_empresa | VMask(
                  empresa.cpf_cnpj_empresa.length <= 11
                    ? "###.###.###-##"
                    : "##.###.###/####-##"
                ) }}
              </span>
            </h4>
            <b-badge class="bg-light badge rounded-pill">
              <b-img
                :src="SyringeIcon"
                alt="Icone que representa as doses de vacina"
              />
              <strong class="text-custom-blue"> Doses distribuídas: </strong>
              <span class="text-custom-blue">
                {{ distribuicao.totalDoses.empresas[empresa.id_empresa] != null ?
                  distribuicao.totalDoses.empresas[empresa.id_empresa] : 0
                }}
              </span>
            </b-badge>
          </template>
          <section class="mt-2 pr-2">
            <div
              v-for="enderecoEmpresa in empresa.empresa_endereco"
              :key="enderecoEmpresa.id_empresa_endereco"
              class="mb-2"
            >
              <b-row v-if="enderecoEmpresa.logradouro !== '' && enderecoEmpresa.bairro !== '' && enderecoEmpresa.cidade != null">
                <b-col class="icon-location">
                  <b-img
                    :src="LocationPinIcon"
                    alt="Icone que representa o endereço"
                  />
                </b-col>
                <b-col class="text-left">
                  <span class="mx-1">{{ enderecoEmpresa.descricao }}</span>
                  <p class="mx-1 mb-0 font-small-4">
                    {{ enderecoEmpresa.logradouro }},
                    {{ enderecoEmpresa.numero === '' ? 'Sem número' : enderecoEmpresa.numero }},
                    {{ enderecoEmpresa.bairro }},
                    {{ enderecoEmpresa.cidade.nome }},
                    {{ enderecoEmpresa.cidade.uf }}
                  </p>
                  <strong
                    v-if="adesoesEnderecoEmpresa != undefined && adesoesEnderecoEmpresa[enderecoEmpresa.id_empresa][enderecoEmpresa.id_empresa_endereco] != undefined
                    "
                    class="mx-1 font-small-4"
                  >
                    Unidade Operacional {{ adesoesEnderecoEmpresa[enderecoEmpresa.id_empresa][enderecoEmpresa.id_empresa_endereco].unidade }}
                  </strong>
                  <strong
                    v-else-if="enderecoEmpresa.id_unidade != null"
                    class="mx-1 font-small-4"
                  >
                    Unidade Operacional {{ enderecoEmpresa.unidade.descricao }}
                  </strong>
                  <strong
                    v-else
                    class="mx-1 font-small-4 text-danger "
                  >
                    <span>
                      ATENÇÃO: Este endereço não está vinculado a uma Unidade Operacional.
                    </span><br>
                    <span class="font-small-2 ml-1">
                      Vincule este endereço a uma Unidade Operacional para prosseguir com a distribuição de doses na Adesão.
                    </span>
                  </strong>
                </b-col>
                <b-col
                  class="text-right"
                  cols="4"
                >
                  <b-row
                    v-for="vacina in vacinas"
                    :key="vacina.id_vacina"
                    class="mb-2"
                  >
                    <b-col>
                      <strong>{{ vacina.nome_tecnico }}</strong>
                    </b-col>
                    <b-col class="text-center with-columm-input" v-b-tooltip.hover="{ title: !empresa.presente_no_grupo ? messageCompanyWithoutGroup : '', placement: 'bottom' }">
                      <b-form-input
                        v-if="enderecoEmpresa.id_unidade == null"
                        type="number"
                        :disabled="!empresa.presente_no_grupo"
                        class="custom-input-table float-right quantity-doses-input"
                        placeholder="0"
                        disabled="disabled"
                      />
                      <distribution-input
                        v-else-if="adesoesEnderecoEmpresa != undefined && adesoesEnderecoEmpresa[enderecoEmpresa.id_empresa][enderecoEmpresa.id_empresa_endereco] != undefined
                          && adesoesEnderecoEmpresa[enderecoEmpresa.id_empresa][enderecoEmpresa.id_empresa_endereco]['vacinas'][vacina.id_vacina] != undefined
                        "
                        :disabled="!empresa.presente_no_grupo"
                        :qtd-doses="Number(adesoesEnderecoEmpresa[enderecoEmpresa.id_empresa][enderecoEmpresa.id_empresa_endereco]['vacinas'][vacina.id_vacina]['saldo'])"
                        @input="
                          updateDoseCompany(enderecoEmpresa.id_empresa_endereco, enderecoEmpresa.id_empresa, vacina.id_vacina, $event)
                        "
                      />
                      <distribution-input
                        v-else
                        :disabled="!empresa.presente_no_grupo"
                        :qtd-doses="0"
                        class="quantity-doses-input"
                        @input="
                          updateDoseCompany(enderecoEmpresa.id_empresa_endereco, enderecoEmpresa.id_empresa, vacina.id_vacina, $event)
                        "
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </section>
        </app-collapse-item>
      </app-collapse>
      <b-row class="mt-4">
        <b-col cols="4">
          <b-button
            v-if="distribuicao.totalDoses.totalGrupo != undefined && distribuicao.totalDoses.totalGrupo > 0"
            variant="custom-blue"
            :disabled="noCompaniesInFilters"
            class="btn-purchases"
            @click="saveAdesaoNacional"
          >
            Salvar adesão
          </b-button>
          <b-button
            v-else
            class="btn-purchases"
            variant="custom-blue"
            disabled="disabled"
          >
            Salvar adesão
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </overlay>
</template>
<script>
import {
  BRow,
  BCol,
  BForm,
  BFormInput,
  BButton,
  BBadge,
  BImg,
  BAlert, BTooltip, VBTooltip,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import SyringeIcon from '@/assets/images/icons/syringe.svg'
import LocationPinIcon from '@/assets/images/icons/locationPin.svg'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'
import DistributionInput from './DistributionInput.vue'
import {
  successCheckCircleMessage,
  warningInfoMessage,
  warningMessage,
} from '@/libs/sweetalerts'

import {
  AdesaoUpdateDoseCompany,
  GetDistribuicaoDosesAdesaoNacional,
  GetAdesoesEnderecoEmpresa,
  SetPayloadAdesaoNacional,
  GetPayloadAdesaoNacional,
  SetChooseCampaignAdesaoNacional,
  SetChooseCompanyAdesaoNacional,
  SetChooseOperadorAdesaoNacional,
} from '@/views/custom-pages/adesao-nacional/helpers'

export default {
  components: {
    BAlert,
    Overlay,
    DistributionInput,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BButton,
    BBadge,
    AppCollapse,
    AppCollapseItem,
    BImg,
    BTooltip
  },

  props: {
    empresas: {
      type: Array,
      default: () => [],
    },
    vacinas: {
      type: Array,
      default: () => [],
    },
    action: {
      default: null,
    },
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      SyringeIcon,
      LocationPinIcon,
      loading: true,
      distribuicao: {},
      adesoesEnderecoEmpresa: {},
      messageCompanyWithoutGroup: 'Essa empresa não faz mais parte desse grupo de empresas'
    }
  },

  computed: {
    getEmpresas() {
      return this.empresas
    },

    noCompaniesInFilters() {
      return this.getEmpresas.length === 0
    },
  },

  mounted() {
    this.distribuicao = GetDistribuicaoDosesAdesaoNacional()
    this.adesoesEnderecoEmpresa = GetAdesoesEnderecoEmpresa()
    this.loading = false
  },

  methods: {
    updateDoseCompany(idEnderecoEmpresa, idEmpresa, idVacina, event) {
      AdesaoUpdateDoseCompany(idEnderecoEmpresa, idEmpresa, idVacina, Number(event))
    },

    onlyNumber(event) {
      if (!helpers.keyIsNumber(event)) {
        event.preventDefault()
      }
    },

    async saveAdesaoNacional() {
      if (this.action === 'edit') {
        await this.editAdesaoNacional()
      } else {
        await this.registerAdesaoNacional()
      }
    },

    async registerAdesaoNacional() {
      this.setLoading(true)

      SetPayloadAdesaoNacional()

      await this.$http.post(this.$api.adesaoNacional(), GetPayloadAdesaoNacional())
        .then(() => {
          successCheckCircleMessage('Sua adesão nacional foi salva com sucesso.', 'Adesão salva!')

          this.$store.commit('adesaoNacionalViewState/setPurchaseUpdate', null)

          this.setNullGrupoAndCampaign()
          this.redirectToList()
        })
        .catch(error => {
          this.handleError(error.response)
        })

      this.setLoading(false)
    },

    async editAdesaoNacional() {
      this.setLoading(true)
      SetPayloadAdesaoNacional()

      await this.$emit('setCompaniesInFilters')

      await this.$http.put(this.$api.adesaoNacional(), GetPayloadAdesaoNacional())
        .then(() => {
          successCheckCircleMessage('Sua adesão nacional foi salva com sucesso.', 'Adesão salva!')

          this.$store.commit('adesaoNacionalViewState/setPurchaseUpdate', null)

          this.setNullGrupoAndCampaign()
          this.redirectToList()
        })
        .catch(error => {
          this.handleError(error.response)
        })

      this.$emit('clearFilters')

      this.setLoading(false)
    },

    setLoading(loading) {
      this.$emit('setLoading', loading)
    },

    setNullGrupoAndCampaign() {
      SetChooseCampaignAdesaoNacional(null)
      SetChooseCompanyAdesaoNacional(null)
      SetChooseOperadorAdesaoNacional(null)
    },

    handleError(response) {
      const errors = response.status === 400 || response.status === 404

      if (errors) {
        warningInfoMessage(
          response.data.errors,
          'Confira os dados e depois salve a adesão.',
          'Entendido',
        )

        return
      }

      warningMessage(
        'Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.',
      )
    },
    redirectToList() {
      this.$router.push(
        {
          name: 'listar-adesao-nacional',
        },
      )
    },
  },

}
</script>
<style scoped lang="scss">
.m-alert {
  margin-top: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
}

.d-none-1000 {
  display: block;
}

.btn-purchases {
  width: 228px;
}

#adesao-nacional-edit {
  .icon-location {
    max-width: 50px;
    line-height: 5rem;
  }
}

@media(width < 1000px) {
  .d-none-1000 {
    display: none;
  }
}
</style>
